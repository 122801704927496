.hack-container {
  width: 100%;
  padding: 80px 40px 60px 40px;
}

.hack-wrapper {
  max-width: 1300px;
  width: 100%;
  padding: 65px 70px;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  margin: 0px auto;
  background: linear-gradient(
    135deg,
    rgba(16, 23, 36, 0.95) 0%,
    rgba(58, 104, 184, 0.2) 50%,
    rgba(16, 23, 36, 0.85) 100%
  );
  border-radius: 16px;
}

.hack-img {
  position: absolute;
  user-select: none;
  bottom: -120px;
  right: -120px;
}

.hack-prompt {
  font-size: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin-bottom: 10px;
}

.hack-header {
  font-size: 30px;
  color: var(--white-txt-color);
}

.hack-descr {
  font-size: 18px;
  opacity: 0.8;
  color: var(--white-txt-color);
  margin: 20px 0px 30px 0px;
  width: 60%;
}

.hack-btn {
  border: none;
  outline: none;
  background: var(--secondary-color);
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  cursor: pointer;
  transform: scale(1);
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.hack-btn:active {
  transform: scale(0.96);
}

.hack-txt-mark {
  color: var(--secondary-color);
  font-weight: 900;
}

.hack-btn::before,
.hack-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #ae310b;
  content: '';
  transition: width 1s;
  z-index: -1;
}

.hack-btn::before {
  left: 0;
}

.hack-btn::after {
  right: 0;
}

.hack-btn:hover::before,
.hack-btn:hover::after {
  width: 100%;
}

.hack-icon {
  color: var(--white-color);
  font-size: 24px;
  font-weight: 900;
  margin-left: 8px;
}

@media (max-width: 1300px) {
  .hack-container {
    padding: 30px 40px 70px 40px;
  }
}

@media (max-width: 1020px) {
  .hack-wrapper {
    padding: 40px 50px;
  }

  .hack-img {
    transform: scale(0.8);
    bottom: -150px;
    right: -150px;
  }

  .hack-header {
    font-size: 26px;
  }

  .hack-descr {
    font-size: 17px;
  }
}

@media (max-width: 768px) {
  .hack-container {
    padding: 20px 30px 60px 30px;
  }
  .hack-wrapper {
    padding: 30px 40px;
  }

  .hack-img {
    transform: scale(0.7);
    bottom: -160px;
    right: -160px;
    z-index: -1;
  }
}

@media (max-width: 600px) {
  .hack-container {
    padding: 15px 30px 50px 30px;
  }

  .hack-img {
    display: none;
  }
  .hack-descr {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .hack-wrapper {
    padding: 25px;
  }

  .hack-prompt {
    font-size: 16px;
  }

  .hack-header {
    font-size: 22px;
  }

  .hack-descr {
    font-size: 15px;
    margin: 10px 0px 20px 0px;
  }
}
