.opener-container {
  width: 100%;
  background: var(--primary-color);
  padding: 60px 40px;
  position: relative;
}

.opener-wrapper {
  display: flex;
  align-items: center;
  gap: 120px;
  max-width: 1300px;
  margin: auto;
}

.opener-box1 {
  flex: 1;
  flex-shrink: 3;
}

.opener-prompt {
  font-size: 18px;
  color: var(--secondary-color);
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.opener-title {
  font-size: 55px;
  font-weight: 900;
  color: var(--white-txt-color);
  width: 120%;
}

.opener-descr {
  font-size: 18px;
  font-weight: 400;
  color: var(--white-txt-color);
  opacity: 0.7;
  margin: 20px 0px 40px 0px;
}

.opener-btn-flex {
  display: flex;
  align-items: center;
  gap: 20px;
}

.opener-btn {
  border: none;
  outline: none;
  background: var(--secondary-color);
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  cursor: pointer;
  transform: scale(1);
  position: relative;
  overflow: hidden;
}

.opener-btn:active {
  transform: scale(0.96);
}

.opener-btn::before,
.opener-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #ae310b;
  content: '';
  transition: width 1s;
  z-index: -1;
}

.opener-btn::before {
  left: 0;
}

.opener-btn::after {
  right: 0;
}

.opener-btn:hover::before,
.opener-btn:hover::after {
  width: 100%;
}

.opener-box2 {
  flex: 1;
  margin-right: -80px;
}

@media (max-width: 1300px) {
  .opener-img {
    width: 100%;
  }
  .opener-box1 {
    flex: 2;
  }

  .opener-title {
    font-size: 45px;
  }
  .opener-descr {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .opener-btn {
    padding: 12px 32px;
    text-wrap: nowrap;
  }
  .opener-box2 {
    margin-right: 0px;
    flex: 3;
  }
}

@media (max-width: 920px) {
  .opener-container {
    padding: 50px 30px;
  }
  .opener-wrapper {
    flex-direction: column-reverse;
    gap: 60px;
  }
  .opener-box1 {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .opener-title {
    width: 100%;
    text-align: center;
    font-size: 40px;
  }
  .opener-descr {
    text-align: center;
    width: 70%;
  }
  .opener-box2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  .opener-img {
    width: 70%;
    height: 60%;
  }
}

@media (max-width: 600px) {
  .opener-container {
    padding: 40px 20px;
  }
  .opener-wrapper {
    gap: 40px;
  }
  .opener-title {
    font-size: 30px;
  }
  .opener-prompt {
    text-align: center;
  }
  .opener-descr {
    width: 100%;
  }
  .opener-btn-flex {
    width: 100%;
    gap: 10px;
  }
  .opener-btn {
    width: 100%;
  }
  .opener-link {
    width: 100%;
  }
  .opener-img {
    width: 100%;
  }
}
