.team-container {
  width: 100%;
  max-width: 1300px;
  margin: auto;
  background: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0px 60px 0px;
}

.team-header {
  font-size: 40px;
  font-weight: 900;
  color: var(--white-txt-color);
  text-align: center;
}

.team-header span {
  color: var(--secondary-color);
}

.team-descr {
  font-size: 16px;
  color: var(--white-txt-color);
  opacity: 0.7;
  margin: 20px 0px 80px 0px;
  text-align: center;
  width: 50%;
  line-height: 25px;
}

.team-swiper {
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  color: var(--white-txt-color) !important;
  font-size: 10px !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 30px !important;
  font-weight: 900;
}

.team-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-img {
  width: 100%;
  height: 350px;
  border-radius: 6px;
  object-fit: cover;
}

.team-name {
  font-size: 18px;
  margin: 10px 0px 6px 0px;
  color: var(--white-txt-color);
}

.team-position {
  font-size: 16px;
  color: var(--white-txt-color);
}

.team-telegram {
  font-size: 14px;
  color: var(--white-txt-color);
  margin-top: 5px;
}

/* Responsive */
@media (max-width: 1300px) {
  .team-container {
    padding: 30px 20px 70px 20px;
  }
  .team-header {
    font-size: 35px;
  }
}

@media (max-width: 992px) {
  .team-card {
    max-width: 350px;
  }
  .team-swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 50px;
    height: 50px;
  }
  .team-descr {
    width: 70%;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 22px !important;
  }
}

@media (max-width: 768px) {
  .team-container {
    padding: 20px 20px 60px 20px;
  }
  .team-header {
    font-size: 30px;
  }

  .team-descr {
    font-size: 14px;
    margin: 15px 0px 40px 0px;
  }
}

@media (max-width: 600px) {
  .team-container {
    padding: 15px 20px 50px 20px;
  }
  .team-header {
    font-size: 25px;
  }
  .team-descr {
    margin: 10px 0px 30px 0px;
    width: 100%;
  }

  .team-card {
    max-width: 300px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
  }
  .team-swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
