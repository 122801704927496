.footer-container {
  width: 100%;
  background: var(--card-color);
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-content {
  max-width: 1300px;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-logo {
  width: 150px;
  margin-bottom: 20px;
}

.footer-links {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-links a {
  color: var(--white-txt-color);
  text-decoration: none;
  font-size: 16px;
  transition: color 0.3s;
}

.footer-links a:hover {
  color: var(--secondary-color);
}

.footer-social {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.footer-social a {
  color: var(--white-txt-color);
  font-size: 28px;
  transition: color 0.3s;
}

.footer-social a:hover {
  color: var(--secondary-color);
}

.footer-message {
  color: var(--white-txt-color);
  text-decoration: none;
  font-size: 16px;
  border: 1px solid var(--white-txt-color);
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.footer-message:hover {
  background-color: var(--secondary-color);
  color: var(--card-color);
}

/* Responsive Styles */
@media (max-width: 1300px) {
  .footer-content {
    width: 95%;
  }

  .footer-links a,
  .footer-message {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer-social a {
    font-size: 20px;
  }

  .footer-message {
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .footer-logo {
    width: 120px;
  }

  .footer-links a,
  .footer-message {
    font-size: 16px;
  }

  .footer-social a {
    font-size: 18px;
  }
}
