.posts-card {
  max-width: 31%;
  padding: 16px;
  border-radius: 5px;
  border: 1px solid var(--secondary-color);
  background: var(--card-color);
  transition: 0.3s all;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1600px) {
  .posts-card {
    max-width: 30%;
  }
}

.posts-card:hover {
  box-shadow: 8px 8px 0px var(--secondary-color);
  transform: scale(1.01);
}

.posts-card-img {
  width: 100%;
  border-radius: 5px;
  height: 280px;
  object-fit: cover;
}

.posts-card-prompt {
  font-size: 16px;
  color: var(--white-txt-color);
  text-transform: uppercase;
  margin: 30px 0px 10px 0px;
}

.posts-card-title {
  font-size: 30px;
  font-size: 900;
  color: var(--secondary-color);
}

.posts-card-descr {
  font-size: 16px;
  color: var(--white-txt-color);
  opacity: 0.7;
  margin: 10px 0px 20px 0px;
}

.posts-card-line {
  border: 1px solid #4d4d4d;
}

.posts-card-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.posts-bottom-wrapper {
  margin-top: auto;
}

.posts-card-btn-flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.posts-card-btn {
  border: 1px solid var(--secondary-color);
  outline: none;
  background: var(--card-color);
  padding: 8px 20px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  cursor: pointer;
  transform: scale(1);
  position: relative;
  overflow: hidden;
}

.posts-card-btn::before,
.posts-card-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  background-color: var(--secondary-color);
  content: '';
  transition: width 0.5s;
  z-index: -1;
}

.posts-card-btn::before {
  left: 0;
}

.posts-card-btn::after {
  right: 0;
}

.posts-card-btn:hover::before,
.posts-card-btn:hover::after {
  width: 100%;
}

.posts-card-btn:active {
  transform: scale(0.96);
}

.posts-card-date-laptop {
  font-size: 14px;
  color: var(--white-txt-color);
  opacity: 0.6;
}

.posts-card-date-mobile {
  font-size: 14px;
  color: var(--white-txt-color);
  opacity: 0.6;
  display: none;
}

@media (max-width: 1400px) {
  .posts-card {
    max-width: 400px;
  }
  .posts-card-img {
    height: 220px;
  }
  .posts-card-prompt {
    margin: 20px 0px 8px 0px;
    font-size: 14px;
  }

  .posts-card-title {
    font-size: 25px;
  }

  .posts-card-descr {
    font-size: 14px;
  }
}

@media (max-width: 1020px) {
  .posts-card {
    max-width: 320px;
  }
  .posts-card:hover {
    box-shadow: 5px 5px 0px var(--secondary-color);
  }
  .posts-card-img {
    height: 180px;
  }
  .posts-card-prompt {
    margin: 10px 0px 8px 0px;
  }
}

@media (max-width: 600px) {
  .posts-card {
    padding: 12px;
    box-shadow: 8px 8px 0px var(--secondary-color);
    transform: scale(1.01);
  }

  .posts-detail-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .posts-card-date-mobile {
    display: block;
  }

  .posts-card-date-laptop {
    display: none;
  }

  .posts-card-prompt {
    margin: 8px 0px;
    font-size: 14px;
  }

  .posts-card-title {
    font-size: 20px;
  }

  .posts-card-btn-flex {
    width: 100%;
  }

  .posts-card-btn {
    width: 100%;
  }

  .posts-link {
    width: 100%;
  }

  .posts-card-descr {
    font-size: 14px;
  }
}
