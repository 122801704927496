@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  scroll-behavior: smooth;
}

:root {
  --primary-color: #020a18;
  --secondary-color: rgb(255, 85, 0);
  --primary-txt-color: #3a68b8;
  --white-txt-color: #ffffff;
  --card-color: #101724;
}

body {
  background: var(--primary-color);
  overflow-x: hidden !important;
}
