.nav-container {
  width: 100%;
  padding: 20px 40px;
  background: var(--primary-color);
}

.nav-wrapper {
  max-width: 1300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.logo-head {
  color: var(--secondary-color);
}

.nav-box {
  display: flex;
  align-items: center;
  width: fit-content;
}

.nav-logo {
  width: 283px;
  height: 50px;
}

.nav-items {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-left: 120px;
}

.nav-item {
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  transition: 0.3s all;
}

.nav-item:hover {
  color: var(--secondary-color);
}

.nav-btn {
  border: none;
  outline: none;
  background: var(--secondary-color);
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  cursor: pointer;
  transform: scale(1);
  position: relative;
  overflow: hidden;
}

.nav-btn::before,
.nav-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #ae310b;
  content: '';
  transition: width 1s;
  z-index: -1;
}

.nav-btn::before {
  left: 0;
}

.nav-btn::after {
  right: 0;
}

.nav-btn:hover::before,
.nav-btn:hover::after {
  width: 100%;
}

.nav-btn:active {
  transform: scale(0.96);
}

.flex-btn-menu {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: row-reverse;
}

.hamburger-react {
  color: white !important;
  display: none;
}

.contact {
  display: none;
}

@media (max-width: 920px) {
  .nav-items {
    position: fixed;
    background: var(--secondary-color);
    flex-direction: column;
    z-index: 999;
    width: 245px;
    top: 92px;
    right: -400px;
    padding: 40px 0px;
    height: 100vh;
    transition: 0.4s all ease-in-out;
  }

  .nav-container {
    background: #0b1b30;
  }

  .open {
    right: 0px;
  }

  .nav-item {
    font-size: 20px;
  }

  .nav-item:hover {
    color: white;
  }
  .hamburger-react {
    display: block;
  }
}

@media (max-width: 600px) {
  .nav-btn {
    display: none;
  }
  .contact {
    display: block;
  }
  .nav-items {
    top: 88px;
  }
}

@media (max-width: 420px) {
  .nav-container {
    padding: 15px 20px;
  }
  .logo-head {
    font-size: 30px;
  }
  .nav-btn {
    padding: 10px 25px;
    font-size: 14px;
  }
  .hamburger-react {
    transform: scale(0.9);
  }
  .nav-items {
    top: 78px;
  }
}
