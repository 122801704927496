.posts-container {
  width: 100%;
  background: var(--primary-color);
  padding: 60px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 1600px;
  margin: auto;
}

.detail1 {
  position: absolute;
  top: 8%;
  left: 4%;
}

.posts-header {
  font-size: 40px;
  font-weight: 900;
  color: var(--white-txt-color);
  text-align: center;
}

.posts-header span {
  color: var(--secondary-color);
}

.posts-descr {
  font-size: 18px;
  color: var(--white-txt-color);
  opacity: 0.7;
  margin: 20px 0px 80px 0px;
  text-align: center;
  width: 40%;
  line-height: 25px;
}

.posts-cards {
  display: flex;
  gap: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.posts-btn {
  border: none;
  outline: none;
  background: var(--secondary-color);
  padding: 15px 40px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white-txt-color);
  cursor: pointer;
  transform: scale(1);
  position: relative;
  overflow: hidden;
  margin-top: 60px;
}

.posts-btn::before,
.posts-btn::after {
  position: absolute;
  top: 0;
  height: 100%;
  width: 0;
  background-color: #ae310b;
  content: '';
  transition: width 1s;
  z-index: -1;
}

.posts-btn::before {
  left: 0;
}

.posts-btn::after {
  right: 0;
}

.posts-btn:hover::before,
.posts-btn:hover::after {
  width: 100%;
}

.posts-btn:active {
  transform: scale(0.96);
}

@media (max-width: 1300px) {
  .posts-container {
    padding: 50px 20px;
  }
  .posts-cards {
    gap: 30px;
  }
  .posts-header {
    font-size: 35px;
  }
}

@media (max-width: 1020px) {
  .posts-cards {
    flex-wrap: wrap;
  }
  .posts-descr {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .posts-header {
    font-size: 30px;
  }

  .posts-descr {
    font-size: 14px;
    margin: 15px 0px 40px 0px;
  }
}

@media (max-width: 600px) {
  .posts-header {
    font-size: 25px;
  }
  .posts-descr {
    margin: 10px 0px 30px 0px;
    width: 100%;
  }
  .posts-btn {
    margin-top: 40px;
    padding: 10px 30px;
    font-size: 16px;
  }
}
